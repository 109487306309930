
import { dispatchCheckUserRefCode, dispatchRegisterUser } from '@/store/main/actions';
import { getLocalSessionId } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import googleClient from '../../../../google_client.json';
import facebookClient from '../../../../facebook_client.json';

@Component({
  components: {
  },
})

export default class SignUpClient extends Vue {
  public file = null;
  public codeResult = {
    checked: false,
    found: false,
  };
  public userData: any = {
    user_type: 'client',
    email: '',
    password: '',
    password2: '',
    first_name: '',
    last_name: '',
    code: '',
  }

  public passwordInputType = 'password';
  public usernameType = 'email';
  public emailError: string = '';
  public formError = false;
  public loadingState = {
    blockBtn: false,
  };

  public async submit() {
    this.formError = false;
    if (!this.userData.email || (this.userData.email && !/(.+)@(.+){1,}\.(.+){2,}/.test(this.userData.email))) {
      this.formError = true;
    }
    if (this.userData.password === '' || this.userData.password !== this.userData.password2){
      this.formError = true;
    }
    if (this.formError) {
      this.$toast.error(this.$t('Неверные учетные данные').toString());
      return;
    }
    this.loadingState.blockBtn = true;
    const response = await dispatchRegisterUser(
      this.$store,
      {
          // user_type: this.userData.user_type,
          password: this.userData.password,
          email: this.userData.email,
          first_name: this.userData.first_name,
          last_name: this.userData.last_name,
          anon_session_id: getLocalSessionId(),
          ref_code: this.userData.code,
      },
    );
    if (response === true) {
      await this.$router.push('/sign-up/select-type');
    } else {
      this.formError = true;
      if (response.email) {
        this.emailError = response.email[0];
        this.$toast.error(response.email[0]);
      } else {
        this.$toast.error(this.$t('Registration failed').toString());
      }
    }
    this.loadingState.blockBtn = false;
  }
  public handleAuthByGoogle() {
    // this.$toast.error(this.$t('Пока недоступно').toString());
    const clientId = googleClient.web.client_id;
    const redirectUri = googleClient.web.redirect_uris[0];
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=profile email&include_granted_scopes=true`;
  }
  public handleAuthByFacebook() {
    // this.$toast.error(this.$t('Пока недоступно').toString());
    const clientId = facebookClient.web.client_id;
    const redirectUri = facebookClient.web.redirect_uri;
    window.location.href = `https://www.facebook.com/v14.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&state=test&scope=public_profile email&response_type=token`;
  }
  public handleAuthByApple() {
      this.$toast.error(this.$t('Пока недоступно').toString());
  }

  public async checkRefCode() {
    if (this.userData.code) {
      const codeFound = await dispatchCheckUserRefCode(this.$store, {code: this.userData.code})
      this.codeResult = {
        checked: true,
        found: codeFound,
      }
    } else {
      this.codeResult = {
        checked: false,
        found: false,
      }
    }
  }

}
